.title {
    color: #888;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.title h2 {
    font-size: 24px;
}

.title h3 {
    font-size: 15px;
}

.title > * {
    margin-top: 10px;
    margin-bottom: 10px;
}

.title.current {
    color: #0e9076;
}

.current-day {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.data {
    display: flex;
    height: 400px;
}

.month {
    flex-grow: 2;
}

.chart {
    flex-grow: 1;
    width: 100%;
    height: 100%;
}

.container {
    margin: 20px 40px;
}

.list {
    flex-grow: 2;
}

.header {
    margin: 40px 0px;
    display: flex;
    align-items: center;
    color: #0e9076;
}

.header .skull-button {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
}

.header .skull-button .skull {
    fill: #004d3e;
    width: 60px;
    height: 60px;
}

.header .skull-button .skull:hover {
    fill: #dc4633;
}

.header .author {
    font-family: "Major Mono Display", monospace;
    font-size: 30px;
    fill: #0e9076;
    margin-right: 12px;
}

.header .progress {
    flex-grow: 1;
    background-color: #0e9076;
    height: 60px;
    position: relative;
    font-family: "Major Mono Display", monospace;
    color: white;
}

.header .progress-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #004d3e;
    height: 100%;
    width: 50%;
    font-size: 30px;
    padding: 0 20px;
}

.header .progress .progress-numbers {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    padding: 0 20px;
    box-sizing: border-box;
}